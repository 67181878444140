import {Asset, AssetsNumberData, CrawledLinksStats, CustomerTopAssets} from "../../../interfaces";

export const SET_STATISTICS_STATE = 'SET_STATISTICS_STATE';
export interface SetStatisticsStateActionType {
    type: typeof SET_STATISTICS_STATE,
    stateName: string,
    nestedStateName: string,
    stateValue: AssetsNumberData | Array<Asset> | CrawledLinksStats | CustomerTopAssets | boolean,
}

export const GET_CUSTOMER_ASSETS = 'GET_CUSTOMER_ASSETS';
export interface getCustomerAssetsActionType {
    type: typeof GET_CUSTOMER_ASSETS
}
export const GET_CUSTOMER_ASSETS_NUMBER = 'GET_CUSTOMER_ASSETS_NUMBER';
export interface getCustomerAssetsNumberActionType {
    type: typeof GET_CUSTOMER_ASSETS_NUMBER
}

export const GET_CRAWLED_LINKS_STATS = 'GET_CRAWLED_LINKS_STATS';
export interface getCrawledLinksStatsActionType {
    type: typeof GET_CRAWLED_LINKS_STATS,
    payload?: number //asset id
}

export const GET_GOOGLE_CRAWLED_LINKS_STATS = 'GET_GOOGLE_CRAWLED_LINKS_STATS';
export interface getGoogleCrawledLinksStatsActionType {
    type: typeof GET_GOOGLE_CRAWLED_LINKS_STATS,
    payload?: number
}

export const GET_CUSTOMER_TOP_ASSETS = 'GET_CUSTOMER_TOP_ASSETS';
export interface getCustomerTopAssetsActionType {
    type: typeof GET_CUSTOMER_TOP_ASSETS
}
export const GET_TOP_PIRATING_WEBSITES = 'GET_TOP_PIRATING_WEBSITES';
export interface getTopPiratingWebsitesActionType {
    type: typeof GET_TOP_PIRATING_WEBSITES
}

export const GET_TOP_PIRATING_WEBSITES_PER_ASSET = 'GET_TOP_PIRATING_WEBSITES_PER_ASSET';
export interface getTopPiratingWebsitesPerAssetActionType {
    type: typeof GET_TOP_PIRATING_WEBSITES_PER_ASSET
    payload?: number
}
export const GET_TOP_REPORTED_WEBSITES_PER_ASSET = 'GET_TOP_REPORTED_WEBSITES_PER_ASSET';
export interface getTopReportedWebsitesPerAssetActionType {
    type: typeof GET_TOP_REPORTED_WEBSITES_PER_ASSET
    payload?: number
}
export const GET_TOP_REMOVED_WEBSITES_PER_ASSET = 'GET_TOP_WEBSITES_PER_ASSET';
export interface getTopRemovedWebsitesPerAssetActionType {
    type: typeof GET_TOP_REMOVED_WEBSITES_PER_ASSET
    payload?: number
}

export const GET_ASSET_LINKS_COUNT_MONTHLY = 'GET_ASSET_LINKS_COUNT_MONTHLY';
export interface getAssetLinksCountMonthlyActionType {
    type: typeof GET_ASSET_LINKS_COUNT_MONTHLY
    payload?: { asset_id?:number, year? : number }
}

export const GET_CRAWLED_LINKS_LATEST_ACTIVITIES = 'GET_CRAWLED_LINKS_LATEST_ACTIVITIES';
export interface getCrawledLinksLatestActivitiesActionType {
    type: typeof GET_CRAWLED_LINKS_LATEST_ACTIVITIES
}

export type StatsActionTypes = SetStatisticsStateActionType | getCustomerAssetsActionType |
                               getCustomerAssetsNumberActionType | getCustomerTopAssetsActionType |
                               getCrawledLinksStatsActionType | getGoogleCrawledLinksStatsActionType |
                               getTopPiratingWebsitesActionType | getTopPiratingWebsitesPerAssetActionType |
                               getTopReportedWebsitesPerAssetActionType | getTopRemovedWebsitesPerAssetActionType |
                               getAssetLinksCountMonthlyActionType | getCrawledLinksLatestActivitiesActionType;
