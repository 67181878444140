/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import * as auth from "../auth/redux/AuthRedux";
import {useDispatch} from "react-redux";
import * as Yup from "yup";
import {useFormik} from "formik";
import {resetPassword} from "../auth/redux/AuthCRUD";
import {SubLayout} from "../../../_metronic/layout/SubLayout";
import clsx from "clsx";
const changePasswordSchema = Yup.object().shape({
    old_password: Yup.string()
        .min(6, 'Minimum 6 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Old Password is required'),
    password: Yup.string()
        .min(6, 'Minimum 6 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required')
        .notOneOf([Yup.ref('old_password')], 'The new password Shouldn\'t be the same as the old one!'),
    password_confirmation: Yup.string()
        .required('Password confirmation is required')
        .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
        })
})

const initialValues = {
    old_password: '',
    password: '',
    password_confirmation: '',
}
export function PasswordResetPage() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues,
        validationSchema: changePasswordSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            setTimeout(() => {
                resetPassword(values.old_password, values.password, values.password_confirmation)
                    .then(({data: {user}}) => {
                        setLoading(false)
                        dispatch(auth.actions.fulfillUser(user))
                    })
                    .catch(() => {
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('Old password in not correct!')
                    })
            }, 1000)
        },
    })

    return (
        <SubLayout>
            <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
            >
                {/* begin::Heading */}
                <div className='text-center mb-10'>
                    <h1 className='text-dark mb-5'>Welcome to Content Shield - Blacklisted</h1>
                    <h2 className='text-dark mb-3'>Choose a new Password Please</h2>
                </div>
                <div className='align-items-center mb-10'>
                    <div className='border-bottom border-gray-300 w-100'></div>
                </div>
                {/* begin::Heading */}

                { formik.status && (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                ) }
                {/* begin::Form group Old Password */}
                <div className='mb-10 fv-row' data-kt-password-meter='true'>
                    <div className='mb-1'>
                        <label className='form-label fw-bolder text-dark fs-6'>Old Password</label>
                        <div className='position-relative mb-3'>
                            <input
                                type='password'
                                placeholder='Old Password'
                                autoComplete='off'
                                {...formik.getFieldProps('old_password')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid': formik.touched.old_password && formik.errors.old_password,
                                    },
                                    {
                                        'is-valid': formik.touched.old_password && !formik.errors.old_password,
                                    }
                                )}
                            />
                            {formik.touched.old_password && formik.errors.old_password && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.old_password}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* end::Form group */}

                {/* begin::Form group Password */}
                <div className='mb-10 fv-row' data-kt-password-meter='true'>
                    <div className='mb-1'>
                        <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                        <div className='position-relative mb-3'>
                            <input
                                type='password'
                                placeholder='Password'
                                autoComplete='off'
                                {...formik.getFieldProps('password')}
                                className={clsx(
                                    'form-control form-control-lg form-control-solid',
                                    {
                                        'is-invalid': formik.touched.password && formik.errors.password,
                                    },
                                    {
                                        'is-valid': formik.touched.password && !formik.errors.password,
                                    }
                                )}
                            />
                            {formik.touched.password && formik.errors.password && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.password}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* end::Form group */}

                {/* begin::Form group Confirm password */}
                <div className='fv-row mb-5'>
                    <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                    <input
                        type='password'
                        placeholder='Password confirmation'
                        autoComplete='off'
                        {...formik.getFieldProps('password_confirmation')}
                        className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                                'is-invalid': formik.touched.password_confirmation && formik.errors.password_confirmation,
                            },
                            {
                                'is-valid': formik.touched.password_confirmation && !formik.errors.password_confirmation,
                            }
                        )}
                    />
                    {formik.touched.password_confirmation && formik.errors.password_confirmation && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.password_confirmation}</span>
                            </div>
                        </div>
                    )}
                </div>
                {/* end::Form group */}

                {/* begin::Action */}
                <div className='text-center'>
                    <button
                        type='submit'
                        id='kt_sign_in_submit'
                        className='btn btn-lg btn-primary w-100 mb-5'
                        disabled={formik.isSubmitting || !formik.isValid}
                    >
                        {!loading && <span className='indicator-label'>Continue</span>}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
                        )}
                    </button>
                </div>
                {/* end::Action */}
            </form>
        </SubLayout>
    )
}
