import * as actions from './statsActionTypes';
import {
    Asset, AssetLinksCount,
    AssetsNumberData, CrawledLinkActivities,
    CrawledLinksStats,
    CustomerTopAssets,
    TopPiratingWebsites, TopWebsitesPerAsset
} from '../../../interfaces';

export interface StatsReducerState {
    assets: Array<Asset>
    assetsNumber: AssetsNumberData,
    crawledLinksStats: CrawledLinksStats,
    googleCrawledLinksStats: CrawledLinksStats,
    customerTopAssets: CustomerTopAssets,
    topPiratingWebsites: TopPiratingWebsites,
    topAssetPiratingWebsites: TopWebsitesPerAsset,
    topAssetReportedWebsites: TopWebsitesPerAsset,
    topAssetRemovedWebsites: TopWebsitesPerAsset,
    assetLinksCount : AssetLinksCount,
    latestActivities : CrawledLinkActivities,
}

const INITIAL_STATE: StatsReducerState = {
    assets: [],
    assetsNumber: {active : 0 , inactive: 0, total :0, loading : true},
    crawledLinksStats: {asset : {ID_asset : -1, asset_name : ''}, reviewed : 0, reported :0, removed :0 , loading : true},
    googleCrawledLinksStats: {asset : {ID_asset : -1, asset_name : ''}, reviewed : 0, reported :0, removed :0 , loading : true},
    customerTopAssets: {pirated : {ID_asset : -1, asset_name : ''},reported : {ID_asset : -1, asset_name : ''},removed : {ID_asset : -1, asset_name : ''}, loading : true},
    topPiratingWebsites: {pirating : {ID_website : -1, domain : ''},reported : {ID_website : -1, domain : ''},removed : {ID_website : -1, domain : ''}, loading : true},
    topAssetPiratingWebsites: {asset : {ID_asset : -1, asset_name : ''}, loading : true},
    topAssetReportedWebsites: {asset : {ID_asset : -1, asset_name : ''}, loading : true},
    topAssetRemovedWebsites: {asset : {ID_asset : -1, asset_name : ''}, loading : true},
    assetLinksCount: {asset : {ID_asset : -1, asset_name : ''},result : [], months : [],loading : true},
    latestActivities: {data : [], loading : true}
};

export default function statsReducer(state: StatsReducerState = INITIAL_STATE, action: actions.StatsActionTypes): StatsReducerState {
    switch (action.type) {
        case actions.SET_STATISTICS_STATE:
            if (!action.nestedStateName) {
                return {
                    ...state,
                    [action.stateName]: action.stateValue,
                };
            }
            return {
                ...state,
                [action.stateName]: {
                    // @ts-ignore
                    ...state[action.stateName],
                    [action.nestedStateName]: action.stateValue,
                },
            };
        default:
            return state;
    }
}
