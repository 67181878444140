import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'
const APP_URL = process.env.REACT_APP_URL || 'api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user`
export const LOGIN_URL = `${API_URL}/auth/login`
export const CSRF_URL = `${APP_URL}/sanctum/csrf-cookie`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const RESET_PASSWORD_URL = `${API_URL}/user/password`

// Server should return AuthModel
export async function login(email: string, password: string) {
  await axios.get(CSRF_URL);
  return axios.post(LOGIN_URL, {email, password})
  // return axios.post(LOGIN_URL, {email, password})
}

// Server should return AuthModel
export function register(email: string, customer_name: string, username: string, password: string,  password_confirmation: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    customer_name,
    username,
    password,
    password_confirmation
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}


export function resetPassword(old_password :string, password :string, password_confirmation :string) {
  return axios.put(RESET_PASSWORD_URL, {
    old_password,
    password,
    password_confirmation})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
