function getCurrentYear(){
    const date = new Date();
    let currentYear = date.getFullYear();
    if(date.getMonth() < 2)
        currentYear--;
    return currentYear;
}
function generateLastNYearsArr(n : number){
    let currentYear = getCurrentYear();
    let availableYears = [currentYear];
    for (let i = n-1 ; i > 0 ; i--) {
        availableYears.push(--currentYear)
    }
    return availableYears;
}
function generateMonths(all= false) {
    if(all)
        return [1,2,3,4,5,6,7,8,9,10,11,12];
    let availableMonths = [];
    let currentMonth =  new Date().getMonth() + 1;
    while (currentMonth>=1) {
        availableMonths.unshift(currentMonth--);
    }
    return availableMonths;
}
function getMonthsLabels(arr : number[]){
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return arr.map( num => months[num - 1]);
}
function processTotalsArrMonthly(arr : {month : number, total : number}[] , months : number[]){
    let res: number[] = []; res.length = months.length; res.fill(0);
    arr.forEach((item) => {
        let index = months.indexOf(item.month);
        if(index != -1)
            res[index] = item.total;
    });
    return res;
}
function downloadFile(data :any, filename ='file'){
    const blob = new Blob([data], { type: 'text/csv' });
    const url= window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.download = filename;
    anchor.href = url;
    anchor.click();
}
function downloadFileByUrl(url : string, filename ='file'){
    const anchor = document.createElement("a");
    anchor.innerText = filename;
    anchor.href = url;
    anchor.click();
}
export {
    getCurrentYear,
    generateMonths,
    getMonthsLabels,
    processTotalsArrMonthly,
    generateLastNYearsArr,
    downloadFile,
    downloadFileByUrl
}