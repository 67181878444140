/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {CrawledLinkActivity} from "../../../interfaces";
type Props = {
  className: string,
  data : CrawledLinkActivity[],
  loading : boolean
}
const colors= ['#2da2fc', '#70ce89', '#e74e6b','#9257a5' ,'#f5b02d'];
const TablesWidget: React.FC<Props> = ({className, data, loading}) => {
  return (
    <div className={`card ${className} h-100`}>
      {/* begin::Header */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1 text-gray-800'>Latest Activities</span>
            <span className='text-muted mt-1 fw-bold fs-7'>The Latest 5 Activities on Assets</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-0 pb-3'>
        <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
          {/* begin::Table body */}
          <tbody>
          <tr className='text-gray-600 fw-bolder'>
              <td scope="col" className='w-50px text-center'>#</td>
              <td scope="col">Asset</td>
              <td scope="col">Website</td>
              <td scope="col">Status</td>
          </tr>
          {
              loading?
                  <tr>
                      <td colSpan={4}>
                          <span className={'rect-loading-skeleton h-250px'}></span>
                      </td>
                  </tr>

                  :
                  data.map((item, index) => (
                          <tr key={item.date}>
                              {/*<td className='w-10px py-5'>{index+1}.</td>*/}
                              <td className='w-50px'>
                                  <div className='symbol symbol-30px me-2'>
                                    <span className='symbol-label fw-boldest fs-5' style={{color: colors[index]}}>
                                        {index+1}
                                    </span>
                                  </div>
                              </td>
                              <td className='py-5'>
                                  <div className='text-dark fw-bolder text-hover-primary mb-1 fs-6 text-gray-800'>
                                      {item.asset.asset_name}
                                  </div>
                                  {/*<span className='text-muted fw-bold d-block'>Movie Creator</span>*/}
                              </td>
                              <td className='text-muted fw-bold py-5'> {item.website.domain}</td>
                              <td className='py-5'>
                                  <span className={'badge ' + (item.status == 'Reported'? 'badge-light-warning' : ( item.status == 'Removed'? 'badge-light-danger' : 'badge-light-success')) }>{item.status}</span>
                              </td>
                          </tr>
                      ))

          }
          </tbody>
          {/* end::Table body */}
        </table>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TablesWidget}
