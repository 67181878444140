import * as actions from '../redux/statsActionTypes';
import {
    Asset,
    AssetsNumberData,
    CrawledLinksStats,
    CustomerTopAssets,
} from '../../../interfaces';

export const SetStatisticsStateAction = (stateName: string, nestedStateName: string,
                                       stateValue:  AssetsNumberData | Array<Asset> | CrawledLinksStats | CustomerTopAssets | boolean) : actions.SetStatisticsStateActionType => ({
    type: actions.SET_STATISTICS_STATE,
    stateName,
    nestedStateName,
    stateValue,
});

export const getCustomerAssetsAction = (): actions.getCustomerAssetsActionType => ({
    type: actions.GET_CUSTOMER_ASSETS,
});
export const getCustomerAssetsNumberAction = (): actions.getCustomerAssetsNumberActionType => ({
    type: actions.GET_CUSTOMER_ASSETS_NUMBER,
});

export const getCrawledLinksStatsAction = (ID_asset?: number): actions.getCrawledLinksStatsActionType => ({
    type: actions.GET_CRAWLED_LINKS_STATS,
    payload: ID_asset,
});

export const getGoogleCrawledLinksStatsAction = (ID_asset?: number): actions.getGoogleCrawledLinksStatsActionType => ({
    type: actions.GET_GOOGLE_CRAWLED_LINKS_STATS,
    payload: ID_asset,
});

export const getCustomerTopAssetsAction = (): actions.getCustomerTopAssetsActionType => ({
    type: actions.GET_CUSTOMER_TOP_ASSETS
});

export const getTopPiratingWebsitesAction = (): actions.getTopPiratingWebsitesActionType => ({
    type: actions.GET_TOP_PIRATING_WEBSITES
});

export const getTopPiratingWebsitesPerAssetAction = (ID_asset?: number): actions.getTopPiratingWebsitesPerAssetActionType => ({
    type: actions.GET_TOP_PIRATING_WEBSITES_PER_ASSET,
    payload: ID_asset,
});
export const getTopReportedWebsitesPerAssetAction = (ID_asset?: number): actions.getTopReportedWebsitesPerAssetActionType => ({
    type: actions.GET_TOP_REPORTED_WEBSITES_PER_ASSET,
    payload: ID_asset,
});
export const getTopRemovedWebsitesPerAssetAction = (ID_asset?: number): actions.getTopRemovedWebsitesPerAssetActionType => ({
    type: actions.GET_TOP_REMOVED_WEBSITES_PER_ASSET,
    payload: ID_asset,
});
export const getAssetLinksCountMonthlyAction = (ID_asset?: number, year?: number): actions.getAssetLinksCountMonthlyActionType => ({
    type: actions.GET_ASSET_LINKS_COUNT_MONTHLY,
    payload: {'asset_id' : ID_asset, 'year' : year},
});
export const getCrawledLinksLatestActivitiesAction = (): actions.getCrawledLinksLatestActivitiesActionType => ({
    type: actions.GET_CRAWLED_LINKS_LATEST_ACTIVITIES
});