import React, {useEffect} from 'react'
import {useDispatch} from "react-redux";
import * as auth from "../../app/modules/auth/redux/AuthRedux";
import {toAbsoluteUrl} from "../helpers";
import {Content} from "./components/Content";

const SubLayout: React.FC = ({children}) => {
    useEffect(() => {
        document.body.classList.add('bg-white')
        return () => {
            document.body.classList.remove('bg-white')
        }
    }, [])
    const dispatch = useDispatch()
    const logout = () => {
        dispatch(auth.actions.logout())
    }
    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
            }}
        >
            {/* begin::Content */}
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                {/* begin::Logo */}
                <a href='#' className='mb-12 mt-3'>
                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/main/logo-1.svg')} className='h-75px' />
                </a>
                {/* end::Logo */}
                {/* begin::Wrapper */}
                <div className='w-lg-700px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
                    <Content>{children}</Content>
                </div>
                {/* end::Wrapper */}
            </div>
            {/* end::Content */}
            {/* begin::Footer */}
            <div className='d-flex flex-center flex-column-auto p-10'>
                <div className='d-flex align-items-center fw-bold fs-6'>
                    <a href='#' className='text-muted text-hover-primary px-2'>
                        About
                    </a>

                    <a href='#' className='text-muted text-hover-primary px-2'>
                        Contact Us
                    </a>

                    <a href='#' onClick={logout} className='text-muted text-hover-primary px-2'>
                        Sign Out
                    </a>
                </div>
            </div>
            {/* end::Footer */}
        </div>
    )
}

export {SubLayout}
