import React, {FC, useState} from "react";
import {Asset} from "../../../interfaces";
import {requestXLSFileDetailedReport} from "../../../../api";
import * as Yup from "yup";
import {useFormik} from "formik";
import clsx from "clsx";
import {generateLastNYearsArr, generateMonths} from "../../../../_metronic/assets/ts/_utils";
import {toast} from "react-toastify";

const initialValues = {
    asset: '',
    year: '',
    month: '',
    type: '',
    status: ''
}
const reportFiltersSchema = Yup.object().shape({
    asset: Yup.string()
        .required('Asset is required'),
    year: Yup.number()
        .required('Year is required'),
    month: Yup.number()
        .required('Month is required'),
    type: Yup.string()
        .required('Links Type is required'),
    status: Yup.string()
        .required('Links Status is required')
})
interface Props {
    assets :  Asset[]
}
const GeneralReport: FC<Props> = (props: Props) =>{
    const [loading, setLoading] = useState(false);

    const {assets} = props;
    const years = generateLastNYearsArr(10);
    const months = generateMonths(true);
    const typesArr =  [{label :'Websites', value : 'websites'},{label :'Google', value : 'google'}]
    const statusArr =  [{label :'All', value : 'all'},
                        {label :'In Progress', value : 'in_progress'},
                        {label :'Removed', value : 'removed'}];

    const formik = useFormik({
        initialValues,
        validationSchema: reportFiltersSchema,
        onSubmit: (values, {setFieldError, setSubmitting, resetForm}) => {
            setLoading(true)
            setTimeout( () => {
                requestXLSFileDetailedReport({asset :values.asset, year : values.year,month: values.month,status: values.status, type: values.type})
                    .then((res) => {
                        toast.success('We received your request! we will generate your detailed report and send it to your email once it is ready.');
                        setLoading(false)
                        setSubmitting(false);
                    })
                    .catch((err) => {
                        toast.error('Something went wrong!');
                        setLoading(false)
                        setSubmitting(false);
                        console.log(err);
                    })
            }, 1000)
        },
    })
    return (
        <div className='card py-4 h-100'>
            <div className='card-header border-0 align-items-baseline'>
                <div className='d-flex justify-content-between w-100'>
                    <h3 className='card-title fw-bolder'>
                        {'Detailed Report Download'}
                    </h3>
                </div>
                <div className="text-muted fw-bold d-block">{'Download Detailed Statistics about your Assets'}</div>
            </div>
            <div className='card-body'>
                <div className='position-relative'>
                    {/* begin::Row */}
                    <div className='row g-0'>
                        {/* begin::Col */}
                        <div className='col'>
                            <form
                                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                                noValidate
                                onSubmit={formik.handleSubmit}>
                                <div className='row fv-row mb-7'>
                                    <div className='col-xl-12'>
                                        <select
                                            className={clsx(
                                                'form-select form-select-solid form-select-lg fw-bold',
                                                {
                                                    'is-invalid': formik.touched.asset && formik.errors.asset,
                                                },
                                                {
                                                    'is-valid': formik.touched.asset && !formik.errors.asset,
                                                }
                                            )}
                                            {...formik.getFieldProps('asset')}
                                        >
                                            <option value=''>Select Asset</option>
                                            {assets.map(item => {
                                                return (<option key={item.ID_asset} value={item.ID_asset}>{item.asset_name}</option>);
                                            })}
                                        </select>
                                        {formik.touched.asset && formik.errors.asset && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.asset}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='row fv-row mb-7'>
                                    <div className='col-xl-6'>
                                        <select
                                            className={clsx(
                                                'form-select form-select-solid form-select-lg fw-bold',
                                                {
                                                    'is-invalid': formik.touched.year && formik.errors.year,
                                                },
                                                {
                                                    'is-valid': formik.touched.year && !formik.errors.year,
                                                }
                                            )}
                                            {...formik.getFieldProps('year')}
                                        >
                                            <option value=''>Select Year</option>
                                            {years.map(item => {
                                                return (<option key={item} value={item}>{item}</option>);
                                            })}
                                        </select>
                                        {formik.touched.year && formik.errors.year && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.year}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-xl-6'>
                                        <select
                                            className={clsx(
                                                'form-select form-select-solid form-select-lg fw-bold',
                                                {
                                                    'is-invalid': formik.touched.month && formik.errors.month,
                                                },
                                                {
                                                    'is-valid': formik.touched.month && !formik.errors.month,
                                                }
                                            )}
                                            {...formik.getFieldProps('month')}
                                        >
                                            <option value=''>Select Month</option>
                                            {months.map(item => {
                                                return (<option key={item} value={item}>{item}</option>);
                                            })}
                                        </select>
                                        {formik.touched.month && formik.errors.month && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.month}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='row fv-row mb-7'>
                                    <div className='col-xl-6'>
                                        <select
                                            className={clsx(
                                                'form-select form-select-solid form-select-lg fw-bold',
                                                {
                                                    'is-invalid': formik.touched.status && formik.errors.status,
                                                },
                                                {
                                                    'is-valid': formik.touched.status && !formik.errors.status,
                                                }
                                            )}
                                            {...formik.getFieldProps('status')}
                                        >
                                            <option value=''>Links Status</option>
                                            {statusArr.map(item => {
                                                return (<option key={item.value} value={item.value}>{item.label}</option>);
                                            })}
                                        </select>
                                        {formik.touched.status && formik.errors.status && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.status}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-xl-6'>
                                        <select
                                            className={clsx(
                                                'form-select form-select-solid form-select-lg fw-bold',
                                                {
                                                    'is-invalid': formik.touched.type && formik.errors.type,
                                                },
                                                {
                                                    'is-valid': formik.touched.type && !formik.errors.type,
                                                }
                                            )}
                                            {...formik.getFieldProps('type')}
                                        >
                                            <option value=''>Links Type</option>
                                            {typesArr.map(item => {
                                                return (<option key={item.value} value={item.value}>{item.label}</option>);
                                            })}
                                        </select>
                                        {formik.touched.type && formik.errors.type && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.type}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <button
                                    type='submit'
                                    id='kt_sign_up_submit'
                                    className='btn btn-lg btn-primary w-100 mt-3 mb-5'
                                    disabled={formik.isSubmitting || !formik.isValid}>
                                    {!loading && <span className='indicator-label align-middle'><i className="fas fa-download fs-6 me-2"></i>Download</span>}
                                    {loading && (
                                        <span className='indicator-progress align-middle' style={{display: 'block'}}>
                                              <span className='spinner-border spinner-border-sm align-middle me-2'></span>Please wait
                                        </span>
                                    )}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GeneralReport;