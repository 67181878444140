/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {generateLastNYearsArr, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {Asset} from "../../../interfaces";

type Props = {
    title :       string
    description : string
    className:    string
    chartColor:   string
    chartHeight:  string
    valuesInput :  {name : string, data : number[]}[]
    categories : string[]
    selectedAsset?: Asset | any
    assets?: Asset[]
    onSelectYear : (year: any) => void
    onSelectAsset : (id: any) => void
    loading : boolean
    // firstInput :  {label : string, values : number[]}
    // secondInput : {label : string, values : number[]}
}

const MonthlyChartWidget: React.FC<Props> = ({title, description, className, valuesInput , categories, onSelectAsset, onSelectYear, selectedAsset, assets,loading, chartColor, chartHeight}) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const years = generateLastNYearsArr(2);
    useEffect(() => {
        if (!chartRef.current) {
            return
        }

        const chart = new ApexCharts(chartRef.current, chartOptions(categories,valuesInput ,chartColor, chartHeight))
        if (chart) {
            chart.render()
        }

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef, valuesInput])

    return (
        <div className={`card h-100 ${className}`}>
            {/* begin::Body */}
            <div className='card-body d-flex flex-column overflow-hidden'>
                {/* begin::Hidden */}
                <div className='d-flex flex-stack flex-wrap pb-3'>
                    <div className='me-2'>
                        <span className='fw-bolder text-gray-800 d-block fs-3'>{title}</span>
                        <span className='text-muted mt-1 fw-bold fs-7'>{description}</span>
                    </div>

                    {/*<div className={`fw-bolder fs-3 text-${chartColor}`}>$15,300</div>*/}
                </div>
                <div className='row'>
                    <div className='col-xxl-6'>
                        <select className='form-select text-gray-700 my-3'
                                data-kt-select2='true'
                                data-placeholder='Select Year'
                                data-allow-clear='true' onChange={(e: any) => onSelectYear(e.target.value)}>
                            {
                                years.map((item) => (
                                    <option value={item} key={item}>{item}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='col-xxl-6'>
                        {
                            assets && (
                                    <select className='form-select text-gray-700 my-3'
                                            data-kt-select2='true'
                                            data-placeholder='Select option'
                                            data-allow-clear='true'
                                            onChange={(e: any) => onSelectAsset(e.target.value)} value={selectedAsset.ID_asset}>
                                        {
                                            loading && assets.length == 0?
                                                <option> loading ... </option>
                                                :
                                                (
                                                    assets.map((item) => (
                                                        <option value={item.ID_asset} key={item.ID_asset}>{item.asset_name}</option>
                                                    ))
                                                )
                                        }
                                    </select>
                            )
                        }
                    </div>
                </div>
                {/* end::Hidden */}

                {/* begin::Chart */}
                {
                    loading?
                        <span className={'rect-loading-skeleton h-200px'}></span>
                        :
                        (  valuesInput.length == 0 ?
                            (
                                <div className={'h-75 d-flex align-items-center justify-content-center h-200px'}>
                                    Sorry, No Data matches your filters!
                                </div>
                            ) :
                            <div ref={chartRef} className='mixed-widget-10-chart'></div>
                        )
                }
                {/* end::Chart */}
            </div>
        </div>
    )
}

const chartOptions = (categories : string[], values: any[],chartColor: string, chartHeight: string): ApexOptions => {
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const borderColor = getCSSVariableValue('--bs-gray-200')
    const secondaryColor = getCSSVariableValue('--bs-gray-400')
    const baseColor = getCSSVariableValue('--bs-' + chartColor)

    return {
        series: values,
        chart: {
            fontFamily: 'inherit',
            type: 'bar',
            height: chartHeight,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
                borderRadius: 5,
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: categories,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        fill: {
            type: 'solid',
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val +'';
                    // return '$' + val + ' revenue'
                },
            },
        },
        colors: [baseColor, secondaryColor],
        grid: {
            padding: {
                top: 10,
            },
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true,
                },
            },
        },
    }
}

export {MonthlyChartWidget}
