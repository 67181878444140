import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import StatsReducer from '../../app/modules/widgets/redux/statsReducer'
import StatsSagas from "../../app/modules/widgets/redux/statsSagas";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  stats: StatsReducer
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga(), ...StatsSagas])
}
