/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Registration} from '../auth/components/Registration'
import {SubLayout} from "../../../_metronic/layout/SubLayout";

export function RegisterPage() {
    return (
        <SubLayout>
            <Registration />
        </SubLayout>
    )
}
