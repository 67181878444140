/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

type Props = {
    className: string
    color: string
    svgIcon: string
    iconColor: string
    title: string
    description: string
    loading: boolean
}

const SimpleStatsWidget1: React.FC<Props> = ({
                                                className,
                                                color,
                                                svgIcon,
                                                iconColor,
                                                title,
                                                description,
                                                loading
                                            }) => {
    return (
        <a href='#' className={`card bg-${color} hoverable ${className}`}>
            {/* begin::Body */}
            <div className='card-body'>
                <KTSVG path={svgIcon} className={`svg-icon-${iconColor} svg-icon-4x ms-n1`} />

                <div className={`text-inverse-${color} fw-bolder fs-2 mb-2 mt-5`}>  { loading?
                    'loading...' : title
                }</div>

                <div className={`fw-bold text-inverse-${color} fs-7`}>
                    {description}
                </div>
            </div>
            {/* end::Body */}
        </a>
    )
}

export {SimpleStatsWidget1}
