/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {AdminRoutes} from "./AdminRoutes";
import {InActiveRoutes} from "./InActiveRoutes";

const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const isAdmin = useSelector<RootState>(({auth}) => auth.user && auth.user.isAdmin, shallowEqual)
  const isActive = useSelector<RootState>(({auth}) => auth.user && auth.user.password_changed_at, shallowEqual)

  return (
    <>
      <Switch>
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from='/auth' to='/' />
        )}

        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />

        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to='/auth/login' />
        ) : (
            !isAdmin ? (
                isActive ? (
                    <MasterLayout>
                        <PrivateRoutes />
                    </MasterLayout>
                ) : (
                    <InActiveRoutes/>
                )
            ) : (
                <AdminRoutes />
            )
        )}
      </Switch>
      <MasterInit />
    </>
  )
}

export {Routes}
