/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {register} from '../redux/AuthCRUD'
import {Link} from 'react-router-dom'
import {getCustomers} from "../../admin/AdminUtils";

const initialValues = {
  customer_name: '',
  username: '',
  email: '',
  password: '',
  changepassword: ''
}

const registrationSchema = Yup.object().shape({
  customer_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  username: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    })
})
export function Registration() {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const getCustomersNames = async () => {
    const res = await getCustomers();
    setCustomers(res.data.customers);
  };
  useEffect(() => {
    getCustomersNames();
  }, [])
  // const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setFieldError, setSubmitting, resetForm}) => {
      setLoading(true)
      setTimeout( () => {
         register(values.email, values.customer_name, values.username, values.password, values.changepassword)
          .then(({data: {accessToken}}) => {
            setLoading(false)
            resetForm();
            // dispatch(auth.actions.login(accessToken))
          })
          .catch((err) => {
            setLoading(false)
            setSubmitting(false)
            const errors = err.response.data.errors;
            for(const field in errors) {
              setFieldError(field, errors[field]);
            }
            // setStatus('Registration process has broken')
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Create Customer Account</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {/* begin::Separator */}
      <div className='align-items-center mb-10'>
        <div className='border-bottom border-gray-300 w-100'></div>
      </div>
      {/* end::Separator */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Customer Name */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='form-label fw-bolder text-dark fs-6'>Customer Name</label>
          <select
              // className='form-select form-select-solid form-select-lg fw-bold'
              className={clsx(
                  'form-select form-select-solid form-select-lg fw-bold',
                  {
                    'is-invalid': formik.touched.customer_name && formik.errors.customer_name,
                  },
                  {
                    'is-valid': formik.touched.customer_name && !formik.errors.customer_name,
                  }
              )}
              {...formik.getFieldProps('customer_name')}
          >
            <option value=''>Select Customer Name...</option>
            {customers.map(item => {
              return (<option key={item} value={item}>{item}</option>);
            })}
          </select>
          {formik.touched.customer_name && formik.errors.customer_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.customer_name}</div>
              </div>
          )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Username */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Username</label>
            <input
              placeholder='Username'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('username')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.username && formik.errors.username,
                },
                {
                  'is-valid': formik.touched.username && !formik.errors.username,
                }
              )}
            />
            {formik.touched.username && formik.errors.username && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.username}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
