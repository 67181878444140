import React, {useEffect, useRef} from 'react'
import {Asset} from "../../../interfaces";
import ApexCharts, {ApexOptions} from "apexcharts";
import {Spinner} from "./StatsWidget";
import {toAbsoluteUrl} from "../../../../_metronic/helpers";

type Props = {
    className: string
    title: string
    description: string
    loading: boolean
    selectedAsset?: Asset | any
    assets?: Asset[]
    onSelect : (id: any) => void
    labels : string[]
    values : number[]
}
const colors= ['#2da2fc', '#70ce89', '#e74e6b','#d6d8e1' ,'#f5b02d'];
const PieChartWidget: React.FC<Props> = ({className, title, description,labels, values, onSelect, selectedAsset, assets, loading}) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    function getPercentage(n : number, arr : number[]){
        return (n*100/arr.reduce((a,b)=>a+b)).toFixed(1);
    }
    useEffect(() => {
        if (!chartRef.current) {
            return
        }

        const chart = new ApexCharts(chartRef.current, getChartOptions(values, labels, colors))
        if (chart) {
            chart.render()
        }

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
    }, [chartRef, labels])
    return (
    <div className={`card h-100 bgi-no-repeat ${className}`}
         style={{
             backgroundPosition: 'right top',
             backgroundSize: '30% auto',
             backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-1.svg')})`,
         }}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
            <h5 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-4 mb-2 text-gray-800'>{title}</span>

                <span className='text-muted fw-bold fs-7'>{description}</span>
            </h5>
            {
                assets && (
                    <div className={'w-100'}>
                        <select className='form-select text-gray-700 my-3'
                                data-kt-select2='true'
                                data-placeholder='Select option'
                                data-allow-clear='true'
                                onChange={(e: any) => onSelect(e.target.value)} value={selectedAsset.ID_asset}>
                            {
                                loading?
                                    <option> loading ... </option>
                                    :
                                    (
                                        assets.map((item) => (
                                            <option value={item.ID_asset} key={item.ID_asset}>{item.asset_name}</option>
                                        ))
                                    )
                            }
                        </select>
                    </div>
                )
            }
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body px-0 py-2 text-center'>
            { loading ?
            (<Spinner size={'lg'} color={'secondary'} className={'spinner-grow mt-4 mb-6'} height={150} width={150}/>)
            :
                (
                   labels.length>0?
                       <>
                            {/* begin::Chart */}
                           <div ref={chartRef} id={`${className}_chart`} style={{height: '300px', display:"inline-block"}} ></div>
                            {/* end::Chart */}
                           <div className={'px-5'}>
                               <table className="table table-row-bordered text-gray-800 fw-bolder align-baseline">
                                   <tbody>
                                   <tr className='text-gray-600'>
                                       <td scope="col" className={'w-25'}>#</td>
                                       <td scope="col" className='text-start'>Website</td>
                                       <td scope="col">Percentage</td>
                                   </tr>
                                   {labels.map((item, index) => (
                                       <tr key={item}>
                                           <th scope="row">
                                               <span style={{'background': colors[index]}} className={'rounded-circle text-white d-inline-block w-25px h-25px p-1 text-center'}>{index+1}</span>
                                           </th>
                                           <td className='text-start'>{item}</td>
                                           <td>
                                               <div className='badge badge-secondary'>{getPercentage(values[index], values)}%</div>
                                           </td>
                                       </tr>
                                   ))}
                                   </tbody>
                               </table>
                           </div>
                       </>
                       :
                       (<div className={'h-75 d-flex align-items-center justify-content-center py-15'}>No Data For this Asset!</div>)
                )
            }
        </div>
        {/* end::Body */}
    </div>
    )

}

export {PieChartWidget}

function getChartOptions(series : number[], labels: string[],  colors: string[]): ApexOptions {
    return {
        series: series,
        colors: colors,
        chart: {
            width: '350',
            type: 'pie',
            animations: {
                speed: 300,
                animateGradually: {
                    enabled: false
                }
            }
        },
        labels: labels,
        legend: {
            show: false,
            position: 'bottom'
        },
        dataLabels: {
            enabled: false,
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 300
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };
}