/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {StatsWidget} from "../../modules/widgets/partials/StatsWidget";
import {RootState} from "../../../setup";
import {StatsActionTypes} from "../../modules/widgets/redux/statsActionTypes";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {
    getAssetLinksCountMonthlyAction, getCrawledLinksLatestActivitiesAction,
    getCrawledLinksStatsAction,
    getCustomerAssetsAction,
    getCustomerAssetsNumberAction,
    getCustomerTopAssetsAction,
    getGoogleCrawledLinksStatsAction,
    getTopPiratingWebsitesAction,
    getTopPiratingWebsitesPerAssetAction,
    getTopRemovedWebsitesPerAssetAction,
    getTopReportedWebsitesPerAssetAction
} from "../../modules/widgets/redux/statsActionCreators";
import {
    Asset, AssetLinksCount,
    AssetsNumberData, CrawledLinkActivities,
    CrawledLinksStats,
    CustomerTopAssets,
    TopPiratingWebsites,
    TopWebsitesPerAsset
} from "../../interfaces";
import {SimpleStatsWidget1} from "../../modules/widgets/partials/SimpleStatsWidget1";
import {SimpleStatsWidget2} from "../../modules/widgets/partials/SimpleStatsWidget2";
import {PieChartWidget} from "../../modules/widgets/partials/PieChartWidget";
import {MonthlyChartWidget} from "../../modules/widgets/partials/MonthlyChartWidget";
import {getCurrentYear} from "../../../_metronic/assets/ts/_utils";
import {TablesWidget} from "../../modules/widgets/partials/TablesWidget";

interface Props {
    assets :  Asset[]
    assetsStatistics : AssetsNumberData,
    linksStatistics : CrawledLinksStats,
    googleLinksStatistics : CrawledLinksStats,
    topAssets : CustomerTopAssets
    topWebsites : TopPiratingWebsites,
    topAssetPiratingWebsites: TopWebsitesPerAsset,
    topAssetReportedWebsites: TopWebsitesPerAsset,
    topAssetRemovedWebsites: TopWebsitesPerAsset,
    assetLinksCount: AssetLinksCount,
    latestActivities: CrawledLinkActivities,
    fetchAssets:  () => void,
    fetchAssetsNumber: () => void,
    fetchLinksStatistics: (id: number) => void,
    fetchGoogleLinksStatistics: (id: number) => void,
    fetchTopAssetsStatistics:() => void,
    fetchTopWebsitesStatistics:() => void,
    fetchTopPiratingWebsitesPerAsset: (id: number) => void,
    fetchTopReportedWebsitesPerAsset: (id: number) => void,
    fetchTopRemovedWebsitesPerAsset: (id: number) => void
    fetchAssetLinksCount: (id: number, year: number) => void
    fetchLatestActivities: () => void,
}
const DashboardWrapper: FC<Props> = (props: Props) =>{
    const [asset, setAsset] =  useState({linksAsset : -1, googleLinksAsset :-1, linksChartAsset :-1,
        piratingWebsitesAsset :-1, reportedWebsitesAsset: -1, removedWebsitesAsset: -1 })
    const [year, setYear] = useState({linksChartYear : getCurrentYear()})
    useEffect(() => {
        props.fetchAssets();
        props.fetchAssetsNumber();
        props.fetchTopAssetsStatistics();
        props.fetchTopWebsitesStatistics();
        props.fetchLatestActivities();
    },[])

    useEffect(() => {
        props.fetchLinksStatistics(asset.linksAsset);
    }, [asset.linksAsset])
    useEffect(() => {
        props.fetchGoogleLinksStatistics(asset.googleLinksAsset);
    }, [asset.googleLinksAsset])

    useEffect(() => {
        props.fetchTopPiratingWebsitesPerAsset(asset.piratingWebsitesAsset);
    }, [asset.piratingWebsitesAsset])

    useEffect(() => {
        props.fetchTopReportedWebsitesPerAsset(asset.reportedWebsitesAsset);
    }, [asset.reportedWebsitesAsset])

    useEffect(() => {
        props.fetchTopRemovedWebsitesPerAsset(asset.removedWebsitesAsset);
    }, [asset.removedWebsitesAsset])

    useEffect(() => {
        props.fetchAssetLinksCount(asset.linksChartAsset , year.linksChartYear);
    }, [asset.linksChartAsset, year.linksChartYear])

    const intl = useIntl()
    const {
        assets, assetsStatistics, linksStatistics, googleLinksStatistics,
        topAssets, topWebsites, topAssetPiratingWebsites, topAssetReportedWebsites, topAssetRemovedWebsites,
        assetLinksCount, latestActivities
    } = props;
    return (<>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        {/* begin::Row */}
        <div className='row gy-5 g-xl-8 mb-5'>
            <div className='col-xxl-4 col-xl-4 col-sm-6'>
                <StatsWidget title={'Assets Summary'} description={'Your Assets'} total={assetsStatistics.total} className={'test'}
                             color={'danger'} iconColor={'gray-900'}
                             onSelect={()=>{}}
                             withDropDown={false} loading={assetsStatistics.loading}
                             stats={[
                                 {title: 'Active Assets', description: 'Number of Active Assets', value: assetsStatistics.active, svgIcon: '/media/icons/duotune/general/gen048.svg'},
                                 {title: 'InActive Assets', description: 'Number of InActive Assets', value: assetsStatistics.inactive, svgIcon: '/media/icons/duotune/general/gen050.svg'}
                             ]}/>
            </div>
            <div className='col-xxl-4 col-xl-4 col-sm-6'>
                <StatsWidget title={`Crawled Links`} description={'Reviewed Links'} total={linksStatistics.reviewed} className={'test'}
                             color={'primary'} iconColor={'gray-600'}
                             assets={assets} selectedAsset={linksStatistics.asset} onSelect = {(id) => setAsset({...asset, linksAsset: id})}
                             withDropDown={false}  loading={linksStatistics.loading}
                             stats={[
                                 {title: 'Reported Links', description: 'Number of Reported Links', value: linksStatistics.reported, svgIcon: '/media/icons/duotune/links/reported-link.svg'},
                                 {title: 'Removed Links', description: 'Number of Removed Links', value: linksStatistics.removed, svgIcon: '/media/icons/duotune/links/removed-link.svg'}
                             ]}/>
            </div>
            <div className='col-xxl-4 col-xl-4 col-sm-6'>
                <StatsWidget title={`Google Crawled Links`}
                             description={'Reviewed Links'} total={googleLinksStatistics.reviewed} className={'test'}
                             color={'success'} iconColor={'gray-600'}
                             assets={assets} selectedAsset={googleLinksStatistics.asset} onSelect = {(id) => setAsset({...asset, googleLinksAsset: id})}
                             withDropDown={false}  loading={googleLinksStatistics.loading}
                             stats={[
                                 {title: 'Reported Links', description: 'Number of Reported Links', value: googleLinksStatistics.reported, svgIcon: '/media/icons/duotune/links/reported-link.svg'},
                                 {title: 'Removed Links', description: 'Number of Removed Links', value: googleLinksStatistics.removed, svgIcon: '/media/icons/duotune/links/removed-link.svg'}
                             ]}/>
            </div>
        </div>
        {/* end::Row */}

        {/* begin::Row */}
        <div className='row gy-5 g-xl-8 mb-5'>
            <div className='col-xxl-4 col-sm-4'>
                <SimpleStatsWidget2
                    className={'websites-stats'} title={'The Overall Most Pirating Website for All Assets'} description={'The Most Website do Pirating'}
                    url={topWebsites.pirating.domain} loading={topWebsites.loading}/>
            </div>
            <div className='col-xxl-4 col-sm-4'>
                <SimpleStatsWidget2
                    className={'websites-stats'} title={'The Overall Most Reported Website for All Assets'} description={'The Most Website do Pirating that was Reported'}
                    url={topWebsites.reported.domain} loading={topWebsites.loading}/>
            </div>
            <div className='col-xxl-4 col-sm-4'>
                <SimpleStatsWidget2
                    className={'websites-stats'} title={'The Website With The Most Assets Removed'}  description={'The Most Website do Pirating that was Removed'}
                    url={topWebsites.removed.domain} loading={topWebsites.loading}/>
            </div>
        </div>
        {/* end::Row */}

        {/* begin::Row */}
        <div className='row gy-5 g-xl-8 mb-5'>
            <div className='col-sm-4 col-xxl-4'>
                <SimpleStatsWidget1
                    className='card-xl-stretch mb-xl-8'
                    svgIcon='/media/icons/duotune/assets/assets.svg'
                    color='danger'
                    iconColor='white'
                    description='The Most Pirated Asset'
                    title={topAssets.pirated.asset_name}
                    loading={topAssets.loading}/>
            </div>
            <div className='col-sm-4 col-xxl-4'>
                <SimpleStatsWidget1
                    className='card-xl-stretch mb-5 mb-xl-8'
                    svgIcon='/media/icons/duotune/assets/reported.svg'
                    color='primary'
                    iconColor='white'
                    description='The Most Reported Asset'
                    title={topAssets.removed.asset_name}
                    loading={topAssets.loading}/>
            </div>
            <div className=' col-sm-4 col-xxl-4'>
                <SimpleStatsWidget1
                    className='card-xl-stretch mb-xl-8'
                    svgIcon='/media/icons/duotune/assets/removed.svg'
                    color='success'
                    iconColor='white'
                    description='The Most Removed Asset'
                    title={topAssets.removed.asset_name}
                    loading={topAssets.loading}/>
            </div>
        </div>
        {/* end::Row */}

        {/* begin::Row */}
        <div className='row gy-5 g-xl-8 mb-5'>
            <div className='col-sm-6 col-xl-4 col-xxl-4'>
                <PieChartWidget className={'top-pirated'} title={'Top Pirating Websites'} description={'The Top 5 Website do Pirating for the selected Asset'}
                                assets={assets} onSelect = {(id) => setAsset({...asset, piratingWebsitesAsset: id})} selectedAsset={topAssetPiratingWebsites.asset}
                                labels={topAssetPiratingWebsites.labels ?? []} values={topAssetPiratingWebsites.values ?? []}
                                loading={topAssetPiratingWebsites.loading}/>
            </div>
            <div className='col-sm-6 col-xl-4 col-xxl-4'>
                <PieChartWidget className={'top-reported'} title={'Top Reported Websites'} description={'The Top 5 Website do Pirating that was Reported'}
                                assets={assets} onSelect = {(id) => setAsset({...asset, reportedWebsitesAsset: id})} selectedAsset={topAssetReportedWebsites.asset}
                                labels={topAssetReportedWebsites.labels ?? []} values={topAssetReportedWebsites.values ?? []}
                                loading={topAssetReportedWebsites.loading}/>
            </div>
            <div className='col-sm-6 col-xl-4 col-xxl-4'>
                <PieChartWidget className={'top-removed'} title={'Top Removed Websites'}  description={'The Top 5 Website do Pirating that was Removed'}
                                assets={assets} onSelect={(id) => setAsset({...asset, removedWebsitesAsset: id})} selectedAsset={topAssetRemovedWebsites.asset}
                                labels={topAssetRemovedWebsites.labels ?? []} values={topAssetRemovedWebsites.values ?? []}
                                loading={topAssetRemovedWebsites.loading}/>
            </div>
        </div>
        {/* end::Row */}


        {/* begin::Row */}
        <div className='row gy-5 g-xl-8 mb-5'>
            <div className='col-md-6 col-xxl-6'>
                <MonthlyChartWidget title={'Assets Yearly Report'} valuesInput={assetLinksCount.result}
                                    description={'A monthly report for reported and removed links'+ (assetLinksCount.asset?.asset_name?` for ${assetLinksCount.asset.asset_name} in ${year.linksChartYear}` :'')}
                                    categories={assetLinksCount.months}
                                    assets={assets} selectedAsset={assetLinksCount.asset}
                                    onSelectAsset={(id) => setAsset({...asset, linksChartAsset: id})}
                                    onSelectYear={(selected_year) => setYear({...year, linksChartYear: selected_year})}
                                    className={'reported-removed-widget'} chartColor={'primary'} chartHeight={'300px'} loading={assetLinksCount.loading}/>
            </div>
            <div className='col-md-6 col-xxl-6'>
                <TablesWidget className={'latest-activities'}
                              data={latestActivities.data} loading={latestActivities.loading}/>

            </div>
        </div>
        {/* end::Row */}


    </>)
}
const mapStateToProps = (state: RootState) => ({
    assets :  state.stats.assets,
    assetsStatistics : state.stats.assetsNumber,
    linksStatistics : state.stats.crawledLinksStats,
    googleLinksStatistics : state.stats.googleCrawledLinksStats,
    topAssets : state.stats.customerTopAssets,
    topWebsites : state.stats.topPiratingWebsites,
    topAssetPiratingWebsites : state.stats.topAssetPiratingWebsites,
    topAssetReportedWebsites : state.stats.topAssetReportedWebsites,
    topAssetRemovedWebsites : state.stats.topAssetRemovedWebsites,
    assetLinksCount : state.stats.assetLinksCount,
    latestActivities : state.stats.latestActivities,
});
const mapDispatchToProps = (dispatch: Dispatch<StatsActionTypes>) => ({
    fetchAssets: () => dispatch(getCustomerAssetsAction()),
    fetchAssetsNumber: () => dispatch(getCustomerAssetsNumberAction()),
    fetchLinksStatistics: (id : number | undefined) => dispatch(getCrawledLinksStatsAction(id)),
    fetchGoogleLinksStatistics: (id: number | undefined) => dispatch(getGoogleCrawledLinksStatsAction(id)),
    fetchTopAssetsStatistics: () => dispatch(getCustomerTopAssetsAction()),
    fetchTopWebsitesStatistics: () => dispatch(getTopPiratingWebsitesAction()),
    fetchTopPiratingWebsitesPerAsset: (id : number | undefined) => dispatch(getTopPiratingWebsitesPerAssetAction(id)),
    fetchTopReportedWebsitesPerAsset: (id : number | undefined) => dispatch(getTopReportedWebsitesPerAssetAction(id)),
    fetchTopRemovedWebsitesPerAsset: (id : number | undefined) => dispatch(getTopRemovedWebsitesPerAssetAction(id)),
    fetchAssetLinksCount: (id : number | undefined, year: number | undefined) => dispatch(getAssetLinksCountMonthlyAction(id, year)),
    fetchLatestActivities: () => dispatch(getCrawledLinksLatestActivitiesAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(DashboardWrapper);