import React, {FC, useEffect, useState} from "react";
import {PageTitle} from "../../../_metronic/layout/core";
import {useIntl} from "react-intl";
import {Asset} from "../../interfaces";
import {RootState} from "../../../setup";
import {Dispatch} from "redux";
import {StatsActionTypes} from "../widgets/redux/statsActionTypes";
import {getCustomerAssetsAction} from "../widgets/redux/statsActionCreators";
import {connect} from "react-redux";
import DetailedReport from "./components/DetailedReport";

interface Props {
    assets :  Asset[],
    fetchAssets:  () => void,
}
const ReportDownloadPage: FC<Props> = (props: Props) =>{
    const intl = useIntl()
    const {assets} = props;

    useEffect(() => {
        props.fetchAssets();
    },[])
    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.REPORTS'})}</PageTitle>
            <div className='row gy-5 g-xl-8 mb-5'>
                <div className='col-12 col-lg-8'>
                    <DetailedReport assets={assets}/>
                </div>
            </div>
        </>)

}
const mapStateToProps = (state: RootState) => ({
    assets :  state.stats.assets,
});
const mapDispatchToProps = (dispatch: Dispatch<StatsActionTypes>) => ({
    fetchAssets: () => dispatch(getCustomerAssetsAction())
})
export default connect(mapStateToProps, mapDispatchToProps)(ReportDownloadPage);