/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

type Props = {
    className: string
    title: string
    description: string
    url: string
    loading: boolean
}

const SimpleStatsWidget2: React.FC<Props> = ({className, url, title, description, loading}) => {
    return (
        <div
            className={`card h-100 shadow bgi-no-repeat ${className}`}
            style={{
                backgroundPosition: 'right top',
                backgroundSize: '30% auto',
                backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-1.svg')})`,
            }}
        >
            {/* begin::Body */}
            <div className='card-body text-center'>
                <div className='card-title fw-bolder text-gray-800 text-hover-dark fs-4'>
                    {title}
                </div>
                {/*<div className='text-gray-600'>{description}</div>*/}
                { loading ?
                    <div className='text-muted  my-6'>Loading...</div>
                :
                    <div  className='fw-bolder text-primary my-6 fs-5 d-block'>
                      {url}
                    </div>
                }

            </div>
            {/* end::Body */}
        </div>
    )
}

export {SimpleStatsWidget2}
