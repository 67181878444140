import axios, { AxiosRequestConfig } from 'axios';
import fs from 'fs';
const API_URL = process.env.REACT_APP_API_URL || 'api'

export const STATS_URL = `${API_URL}/stats`
export const REPORTS_URL = `${API_URL}/reports`
export const GET_CUSTOMER_ASSETS_URL = `${API_URL}/user/assets`

export function getCustomerAssetsApi() {
    return axios.get(GET_CUSTOMER_ASSETS_URL);
}
export function getCustomerAssetsStatsApi() {
    return axios.get(`${STATS_URL}/assets`);
}
export function getCustomerTopAssetsStatsApi() {
    return axios.get(`${STATS_URL}/top_assets`);
}
export function getTopWebsitesStatsApi() {
    return axios.get(`${STATS_URL}/top_websites`);
}
export function getCrawledLinksStatsApi(asset_id?: number) {
    const params : any = {}
    if(asset_id && asset_id !== -1)
        params.asset_id = asset_id;
    return axios.get(`${STATS_URL}/reported_links`, {params :params});
}
export function getGoogleCrawledLinksStatsApi(asset_id?: number) {
    const params : any = {}
    if(asset_id && asset_id !== -1)
        params.asset_id = asset_id;
    return axios.get(`${STATS_URL}/google_reported_links`, {params :params});
}
export function getTopPiratingWebsitesPerAssetApi(asset_id?: number) {
    const params : any = {}
    if(asset_id && asset_id !== -1)
        params.asset_id = asset_id;
    return axios.get(`${STATS_URL}/top_pirating_websites`, {params :params});
}
export function getTopReportedWebsitesPerAssetApi(asset_id?: number) {
    const params : any = {}
    if(asset_id && asset_id !== -1)
        params.asset_id = asset_id;
    return axios.get(`${STATS_URL}/top_reported_websites`, {params :params});
}
export function getTopRemovedWebsitesPerAssetApi(asset_id?: number) {
    const params : any = {}
    if(asset_id && asset_id !== -1)
        params.asset_id = asset_id;
    return axios.get(`${STATS_URL}/top_removed_websites`, {params :params});
}
export function getLinksCountMonthlyApi(data : { asset_id?:number, year? : string }) {
    const params : any = {year : data.year}
    if(data.asset_id && data.asset_id !== -1)
        params.asset_id = data.asset_id;
    return axios.get(`${STATS_URL}/monthly_links`, {params :params});
}
export function getLatestCrawledLinksActivitiesApi() {
    return axios.get(`${STATS_URL}/latest_activities`);
}
export function downloadGeneralReportApi(asset_id?: number) {
    const params : any = {}
    if(asset_id && asset_id !== -1)
        params.asset_id = asset_id;
    return axios.get(`${REPORTS_URL}/general`, {params :params});
}

export const downloadXLSFileGeneralReport = async (asset_id: number | string) => {
    const params : any = {}
    if(asset_id && asset_id !== -1 && asset_id !== '-1')
        params.asset_id = asset_id;
    return downloadXLSFile(`${REPORTS_URL}/general/excel`, params)
}
export const downloadXLSFileDetailedReport = async (params :{ asset : string, year: string, month: string, status: string, type: string}) => {
    return downloadXLSFile(`${REPORTS_URL}/detailed/direct`, params)
}
export const requestXLSFileDetailedReport = async (params :{ asset : string, year: string, month: string, status: string, type: string}) => {
    return axios.get(`${REPORTS_URL}/detailed/`, {params :params});
}
export const downloadXLSFile = async (url : string, params : any) => {
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = {'Content-Type': 'blob'};
    const config: AxiosRequestConfig = {method: 'GET', url:url , responseType: 'arraybuffer', headers, params :params};

    try {
        const response = await axios(config);
        let outputFilename = response.headers['x-filename'];
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();

    } catch (error : any) {
        console.log(error);
    }
}