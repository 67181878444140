import React, {FC, useState} from "react";
import {Asset} from "../../../interfaces";
import {downloadXLSFileGeneralReport} from "../../../../api";
import {Spinner} from "../../widgets/partials/StatsWidget";

interface Props {
    assets :  Asset[]
}
const GeneralReport: FC<Props> = (props: Props) =>{
    const [asset, setAsset] =  useState(-1);
    const [loading, setLoading] = useState(false);

    const {assets} = props;
    //this section as csv
    // const startDownload = useCallback( (asset) => {
    //     // don't send again while we are sending
    //     if (loading) return
    //     setLoading(true)
    //     downloadGeneralReportApi(asset).then(response => {
    //         if (response.status === 200) {
    //             let filename = response.headers['x-filename'];
    //             downloadFile(response.data,filename);
    //         } else {
    //             console.log('Error');
    //         }
    //         setLoading(false)
    //     });
    // }, [loading]);
    const startDownload = (asset : number) => {
        if (loading) return
        setLoading(true)
        downloadXLSFileGeneralReport(asset).then(response => {
            console.log(response);
            setLoading(false)
        });
    }

    return (
        <div className='card py-4'>
            <div className='card-header border-0 align-items-baseline'>
                <div className='d-flex justify-content-between w-100'>
                    <h3 className='card-title fw-bolder'>
                        {'General Report Download'}
                    </h3>
                </div>
                <div className="text-muted fw-bold d-block">{'Download General Statistics about all Assets or any Asset you want'}</div>
            </div>
            <div className='card-body'>
                <div className='position-relative'>
                    {/* begin::Row */}
                    <div className='row g-0'>
                        {/* begin::Col */}
                        <div className='col'>
                            {
                                assets && (
                                    <div>
                                        <select className='form-select form-select-solid text-gray-700'
                                                data-kt-select2='true'
                                                data-placeholder='Select option'
                                                data-allow-clear='true'
                                                onChange={(e: any) => setAsset(e.target.value)}
                                                value={asset}>
                                            <>
                                                <option value={-1}> All </option>
                                                {
                                                    assets.map((item) => (
                                                        <option value={item.ID_asset}
                                                                key={item.ID_asset}>{item.asset_name}</option>
                                                    ))
                                                }
                                            </>
                                        </select>
                                    </div>
                                )
                            }
                            <button
                                className="btn btn-primary float-end mt-6 fs-7"
                                onClick={()=>startDownload(asset)}>
                                {loading? <Spinner size={'sm'} color={'secondary'} className={'me-2'}/>: <i className="fas fa-download fs-6 me-2"></i>}
                                Download</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GeneralReport;