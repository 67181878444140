import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PasswordResetPage} from "../modules/accounts/PasswordResetPage";
export function InActiveRoutes() {
    return (
        <Switch>
            <Route path='/password-reset' component={PasswordResetPage} />
            <Redirect from='/auth' to='/password-reset' />
            <Redirect exact from='/' to='/password-reset' />
            <Redirect exact from='/dashboard' to='/password-reset' />
            <Redirect to='error/404' />
        </Switch>
    )
}
