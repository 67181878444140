/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import CountUp from "react-countup";
import {Asset} from "../../../interfaces";

type Props = {
    title: string
    description: string
    total:  number
    selectedAsset?: Asset | any
    assets?: Asset[]
    className: string
    color: string
    onSelect : (id: any) => void
    withDropDown : boolean
    loading : boolean
    iconColor: string
    stats : {
        title : string
        description : string
        value : number
        svgIcon: string
    }[]
}

export const Spinner: React.FC<any> = ({color, size, className, height , width}) => {
    return (
        <div  className={`spinner-border text-${color} ${className} spinner-border-${size}`} role="status" style={{height : height, width : width}}>
            <span className="visually-hidden">Loading...</span>
        </div>
    )
}
const StatsWidget: React.FC<Props> = ({
                                        className,
                                        color,
                                        iconColor,
                                        title,
                                        description,
                                        total,
                                        onSelect,
                                        assets,
                                        selectedAsset,
                                        withDropDown,
                                        loading,
                                        stats
                                      }) => {
    return (
        <div className={`card ${className} h-100`}>
        {/* begin::Header */}
        <div className={`card-header border-0 align-items-baseline py-5 bg-${color}`}>
            <div className='d-flex justify-content-between w-100 mb-10'>
                <h3 className='card-title fw-bolder text-white'>
                    {title}
                </h3>
                <div  className='card-toolbar'>
                    <div>
                        {/* begin::Menu */}
                        <button
                            type='button'
                            className='btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color- border-0 me-n3'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >
                            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                        </button>
                        {/*<Dropdown1 />*/}
                        {
                            withDropDown &&
                            (
                                <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true'>
                                    <div className='px-7 py-5'>
                                        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                                    </div>

                                    <div className='separator border-gray-200'></div>

                                </div>
                            )}
                        {/* end::Menu */}
                    </div>
                </div>
            </div>
            { description &&
                (
                    <div className='mb-18 text-center w-100'>
                        <h3 className='text-white mb-4'>{description}</h3>
                        {loading? <Spinner size={'lg'} color={'secondary'}/>: <h4 className='text-white'><CountUp end={total} duration={2}/></h4>}
                    </div>
                )
            }
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body p-0'>
            {/* begin::Stats */}
            <div className='card-p mt-n20 position-relative'>
                {/* begin::Row */}
                <div className='row g-0'>
                    {/* begin::Col */}
                    <div className='col bg-white px-6 py-4 rounded-2 mb-7 shadow' style={{minHeight: '213px'}}>
                        {
                            assets && (
                               <div>
                                   <select className='form-select text-gray-700'
                                           data-kt-select2='true'
                                           data-placeholder='Select option'
                                           data-allow-clear='true'
                                           onChange={(e: any) => onSelect(e.target.value)} value={selectedAsset.ID_asset}>
                                       {
                                           loading?
                                               <option> loading ... </option>
                                               :
                                               (
                                                   assets.map((item) => (
                                                       <option value={item.ID_asset} key={item.ID_asset}>{item.asset_name}</option>
                                                   ))
                                               )
                                       }
                                   </select>
                               </div>
                            )
                        }
                        {stats.map((item) => (
                             // begin::Item
                            <div className='d-flex align-items-center p-5' key={item.title}>
                                {/* begin::Icon */}
                                <span className='svg-icon svg-icon-info me-5'>
                                 <KTSVG path={item.svgIcon} className={`svg-icon-${iconColor} svg-icon-2x ms-n1`} />
                                </span>
                                {/* end::Icon */}
                        {/* begin::Title */}
                            <div className='flex-grow-1 me-2'>
                            <a href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                                {item.title}
                            </a>
                            <span className='text-muted fw-bold d-block'>{item.description}</span>
                            </div>
                        {/* end::Title */}
                        {/* begin::Lable */}
                                {loading? <Spinner size={'sm'} color={'gray-600'}/>:
                                    <span className='fw-bolder py-1'><CountUp end={item.value}  duration={2}/></span>}
                        {/* end::Lable */}
                            </div>
                         // end::Item
                        ))}
                    </div>
                    {/* end::Col */}
                </div>
                {/* end::Row */}
            </div>
            {/* end::Stats */}
        </div>
        {/* end::Body */}
    </div>
    )

}

export {StatsWidget}
