import {
    put, call, takeLatest,
} from 'redux-saga/effects';
import {SetStatisticsStateAction} from "./statsActionCreators";
import {AxiosResponse} from "axios";
import {
    getMonthsLabels,
    generateMonths,
    processTotalsArrMonthly
} from '../../../../_metronic/assets/ts/_utils';
import {
    Asset,
    AssetsNumberData,
    CrawledLinksStats,
    RawAssetLinksCount,
    TopWebsitesPerAsset
} from "../../../interfaces";
import {
    getCrawledLinksStatsApi,
    getCustomerAssetsApi,
    getCustomerAssetsStatsApi,
    getCustomerTopAssetsStatsApi,
    getGoogleCrawledLinksStatsApi,
    getLatestCrawledLinksActivitiesApi,
    getLinksCountMonthlyApi,
    getTopPiratingWebsitesPerAssetApi,
    getTopRemovedWebsitesPerAssetApi,
    getTopReportedWebsitesPerAssetApi,
    getTopWebsitesStatsApi
} from "../../../../api";
import {toast} from "react-toastify";
import * as types from '../redux/statsActionTypes';

function* fetchCustomerAssets(){
    try {
        const response: AxiosResponse<Asset[]> = yield call(getCustomerAssetsApi);
        if (response.status === 200) {
            yield put(SetStatisticsStateAction('assets', '', response.data));
        } else {
            toast.error('Something went wrong!');
        }
    } catch (e) {
        toast.error('Something went wrong!');
    }
}
function* fetchAssetsNumberSaga(){
    const stateName = 'assetsNumber';
    try {
        yield put(SetStatisticsStateAction(stateName, 'loading', true));
        const response: AxiosResponse<AssetsNumberData> = yield call(getCustomerAssetsStatsApi);
        if (response.status === 200) {
            yield put(SetStatisticsStateAction(stateName, '', response.data));
        } else {
            toast.error('Something went wrong!');
        }
    } catch (e) {
        toast.error('Something went wrong!');
    } finally {
        yield put(SetStatisticsStateAction(stateName, 'loading', false));
    }
}
function* fetchCrawledLinksStats(action: types.getCrawledLinksStatsActionType){
    yield getStats('crawledLinksStats', action.payload, getCrawledLinksStatsApi);
}
function* fetchGoogleCrawledLinksStats(action : types.getGoogleCrawledLinksStatsActionType){
    yield getStats('googleCrawledLinksStats', action.payload, getGoogleCrawledLinksStatsApi);
}
function* fetchCustomerTopAssets(){
    const stateName = 'customerTopAssets';
    try {
        yield put(SetStatisticsStateAction(stateName, 'loading', true));
        const response: AxiosResponse<CrawledLinksStats> = yield call(getCustomerTopAssetsStatsApi);
        if (response.status === 200) {
            yield put(SetStatisticsStateAction(stateName, '', response.data));
        } else {
            toast.error('Something went wrong!');
        }
    } catch (e) {
        toast.error('Something went wrong!');
    } finally {
        yield put(SetStatisticsStateAction(stateName, 'loading', false));
    }
}
function* fetchTopPiratingWebsites(){
    const stateName = 'topPiratingWebsites';
    try {
        yield put(SetStatisticsStateAction(stateName, 'loading', true));
        const response: AxiosResponse<CrawledLinksStats> = yield call(getTopWebsitesStatsApi);
        if (response.status === 200) {
            yield put(SetStatisticsStateAction(stateName, '', response.data));
        } else {
            toast.error('Something went wrong!');
        }
    } catch (e) {
        toast.error('Something went wrong!');
    } finally {
        yield put(SetStatisticsStateAction(stateName, 'loading', false));
    }
}

function* fetchTopPiratingWebsitesPerAsset(action: types.getCrawledLinksStatsActionType){
    yield getStats('topAssetPiratingWebsites', action.payload, getTopPiratingWebsitesPerAssetApi, processTopWebsitesArray);
}
function* fetchTopReportedWebsitesPerAsset(action : types.getGoogleCrawledLinksStatsActionType){
    yield getStats('topAssetReportedWebsites', action.payload, getTopReportedWebsitesPerAssetApi, processTopWebsitesArray);
}
function* fetchTopRemovedWebsitesPerAsset(action : types.getGoogleCrawledLinksStatsActionType){
    yield getStats('topAssetRemovedWebsites', action.payload, getTopRemovedWebsitesPerAssetApi, processTopWebsitesArray);
}

function* fetchAssetLinksCount(action : types.getAssetLinksCountMonthlyActionType){
    yield getStats('assetLinksCount', action.payload, getLinksCountMonthlyApi, processMonthlyLinksArray);
}

function* fetchLinksLatestActivity(action : types.getCrawledLinksLatestActivitiesActionType){
    yield getStats('latestActivities', null, getLatestCrawledLinksActivitiesApi );
}

function* getStats(stateName: string, payload : any, apiFunction : any, processFunction : any = null){
    try {
        yield put(SetStatisticsStateAction(stateName, 'loading', true));
        const response: AxiosResponse<CrawledLinksStats> = yield call(apiFunction, payload);
        if (response.status === 200) {
            let res =  response.data;
            if(processFunction)
                res = processFunction(res);
            yield put(SetStatisticsStateAction(stateName, '', res));
        } else {
            toast.error('Something went wrong!');
        }
    } catch (e) {
        toast.error('Something went wrong!');
    } finally {
        yield put(SetStatisticsStateAction(stateName, 'loading', false));
    }
}
function processTopWebsitesArray(data : TopWebsitesPerAsset){
    let labels : string[] =[];
    let values : number[] =[];
    data.result && data.result.forEach((item)=>{
        labels.push(item.website.domain);
        values.push(item.total);
    });
    data.labels = labels;
    data.values = values;
    return data;
}
function processMonthlyLinksArray(data : RawAssetLinksCount){
    if(data.reported.length == 0 && data.removed.length == 0)
        return { asset : data.asset,
                 result : [],
                 months : [],
                 loading : false };
    let all = data.year !== new Date().getFullYear().toString();
    let months = generateMonths(all);
    let reported = {
        name : 'Reported Links',
        data : processTotalsArrMonthly(data.reported, months)
    };
    let removed = {
        name : 'Removed Links',
        data : processTotalsArrMonthly(data.removed, months)
    }
    return {
        asset : data.asset,
        result : [reported, removed],
        months : getMonthsLabels(months),
        loading : false
    }
}
const sagas = [
    takeLatest(types.GET_CUSTOMER_ASSETS, fetchCustomerAssets),
    takeLatest(types.GET_CUSTOMER_ASSETS_NUMBER, fetchAssetsNumberSaga),
    takeLatest(types.GET_CRAWLED_LINKS_STATS, fetchCrawledLinksStats),
    takeLatest(types.GET_GOOGLE_CRAWLED_LINKS_STATS, fetchGoogleCrawledLinksStats),
    takeLatest(types.GET_CUSTOMER_TOP_ASSETS, fetchCustomerTopAssets),
    takeLatest(types.GET_TOP_PIRATING_WEBSITES, fetchTopPiratingWebsites),
    takeLatest(types.GET_TOP_PIRATING_WEBSITES_PER_ASSET, fetchTopPiratingWebsitesPerAsset),
    takeLatest(types.GET_TOP_REPORTED_WEBSITES_PER_ASSET, fetchTopReportedWebsitesPerAsset),
    takeLatest(types.GET_TOP_REMOVED_WEBSITES_PER_ASSET, fetchTopRemovedWebsitesPerAsset),
    takeLatest(types.GET_ASSET_LINKS_COUNT_MONTHLY, fetchAssetLinksCount),
    takeLatest(types.GET_CRAWLED_LINKS_LATEST_ACTIVITIES, fetchLinksLatestActivity),
];

export default sagas;