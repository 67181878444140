import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RegisterPage} from '../modules/admin/RegisterPage'
export function AdminRoutes() {
    return (
        <Switch>
            <Route path='/admin/register' component={RegisterPage} />
            <Redirect from='/auth' to='/admin/register' />
            <Redirect from='/' to='/admin/register' />
            <Redirect to='error/404' />
        </Switch>
    )
}
